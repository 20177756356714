import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../slider/SliderStyle.css";

import { Autoplay, Navigation, Mousewheel, A11y } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import 'swiper/css/scrollbar';

function slider({ data }) {
  return (
    <>
      <div className="blue-curv-section">
        <div className="top-curv">
          <span></span>
        </div>
        <div className="curv-cont">
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <h3 className="titl-h3 mb-5">{data.title}</h3>
              </div>
              <div className="col-lg-12">
                {/* list area start */}
                <div className="evalution-row three-swp-card">
                  <Swiper
                    modules={[Autoplay, Navigation, Mousewheel, A11y]}
                    slidesPerView={3}
                    loop={true}
                    centeredSlides={true}
                    spaceBetween={0}
                    // autoplay={{ delay: 4000, disableOnInteraction: false }}
                    navigation
                    // scrollbar={{ draggable: true }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                    className="mySwiper"
                  >
                    {data.cardData.map((card) => {
                      return (
                        <SwiperSlide>
                          <div className="y-card-sm-blue d-flex flex-column justify-content-between">
                            {/* <div className="row"> */}
                            {/* <div className="col-md-12"> */}
                            <h4 className="yor-tx-white txt-35 fw-bold">
                              {card[0]}
                            </h4>
                            <p className="txt-25 yor-tx-white opacity">
                              {card[1]}
                            </p>
                            {/* </div> */}
                            {/* </div> */}
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                {/* list area end */}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-curv">
          <span></span>
        </div>
      </div>
    </>
  );
}

export default slider;
