import { React, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import $ from "jquery";

import CashflowFinances from "../components/yore-finances/CashflowFinances";
import NetworthFinances from "../components/yore-finances/NetworthFinances";
import CreditHealthFinances from "../components/yore-finances/CreditHealthFinances";
import Loaning from "../components/yore-finances/Loaning";
import BudgetFinances from "../components/yore-finances/BudgetFinances";

import crvLeft from "../../src/assets/images/crv-left.png";
import crvRight from "../../src/assets/images/crv-right.png";
// import Marquee from "react-fast-marquee";

import "../../src/components/footer/Footer.css";
// import '../../src/assets/css/Style.css';

let tabData = {
  CashflowFinances: [
    "Cash Flow Manager",
    "Money In - Money Out - Money Managed",
    "Keep your cash flow on track",
    <CashflowFinances />,
  ],
  NetworthFinances: [
    "Net Worth Analysis",
    "Know yore worth - financially",
    "With yore Net Worth Analyzer ",
    <NetworthFinances />,
  ],
  CreditHealthFinances: [
    "Credit Health Manager",
    "Never guess about your credit health again",
    "Get yore free analysis now",
    <CreditHealthFinances />,
  ],
  Loaning: [
    "Loaning",
    "Borrow with ease, lend with peace",
    "We ensure hassle-free Loaning",
    <Loaning />,
  ],
  BudgetFinances: [
    "Budget Tracker",
    "Don't just dream of a better financial future",
    "Do it with yore budget planer",
    <BudgetFinances />,
  ],
};

function YoreFinances({ dValue = "CashflowFinances" }) {
  const [activeTab, setActiveTab] = useState(dValue);

  useEffect(() => {
    // window.AOS.init({ // Initialization
    //   duration: 2000
    // });

    $(".prod-bar .nav-tabs a").click(function () {
      var position = $(this).parent().position();
      var width = $(this).parent().width();
      $(".prod-bar .slider").css({ left: +position.left, width: width });
    });
    var actWidth = $(".prod-bar .nav-tabs")
      .find(".active")
      .parent("li")
      .width();
    var actPosition = $(".prod-bar .nav-tabs .active").position();
    $(".prod-bar .slider").css({ left: +actPosition.left, width: actWidth });

    // var tabs = $('.tabs');
    // var selector = $('.tabs .yr-tab').find('a').length;
    // //var selector = $(".tabs").find(".selector");
    // var activeItem = tabs.find('.active');
    // var activeWidth = activeItem.innerWidth();
    // $(".selector").css({
    //   "left": activeItem.position.left + "px",
    //   "width": activeWidth + "px"
    // });

    // $(".tabs .yr-tab").on("click","a",function(e){
    //   e.preventDefault();
    //   $('.tabs .yr-tab a').removeClass("active");
    //   $(this).addClass('active');
    //   var activeWidth = $(this).innerWidth();
    //   var itemPos = $(this).position();
    //   $(".selector").css({
    //     "left":itemPos.left + "px",
    //     "width": activeWidth + "px"
    //   });
    // });
  });

  return (
    <div className="yore-finances-page">
      <Header />

      <div className="titl-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="prod-titl text-center">
                <h2>
                  <span className="blue-tx">Yore</span> finances, our
                  <br />
                  expertise
                </h2>
                <h4 className="yor-tx-blue py-2">
                  Ignite your financial&nbsp;wellness
                </h4>
                <button className="yor-btn yor-bg-blue yor-tx-white mt-3 px-5 py-3">
                  Get Exclusive Access{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="yore-prod-tab-section">
        <div className="container">
          <div className="tile prod-bar">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {Object.keys(tabData).map((elem) => {
                return (
                  <li className="nav-item" role="presentation">
                    <a
                      href="/#"
                      className={`nav-link ${dValue === elem ? "active" : ""}`}
                      id={`${elem}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${elem}`}
                      type="button"
                      role="tab"
                      aria-controls={elem}
                      aria-selected="true"
                      onClick={() => setActiveTab(elem)}
                    >
                      <span></span>
                      <h6>{tabData[elem][0]}</h6>
                    </a>
                  </li>
                );
              })}

              <div className="slider">
                <div className="curv-left">
                  <img
                    src={crvLeft}
                    className="crv-left"
                    alt="left-curv-icon"
                  />
                </div>
                <div className="curv-right">
                  <img
                    src={crvRight}
                    className="crv-right"
                    alt="right-curv-icon"
                  />
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

      {/* Section Start */}
      <div className="blue-curv-section">
        <div className="top-curv">
          <span></span>
        </div>
        <div className="curv-cont">
          <div className="container">
            <div className="row top-space">
              <div className="col-lg-10 m-auto text-center">
                <h3 className="titl-h3">{tabData[activeTab][1]}</h3>
                <h5 className="fs-3 pt-2 text-primary">
                  {tabData[activeTab][2]}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-curv">
          <span></span>
        </div>
      </div>
      {/* Section End */}

      {/* product tab conten start */}
      <div className="yore-product-section">
        <div className="tab-content" id="myTabContent">
          {Object.keys(tabData).map((elem) => {
            return (
              <div
                className={`tab-pane fade ${
                  dValue === elem ? "show active" : ""
                }`}
                id={elem}
                role="tabpanel"
                aria-labelledby={`${elem}-tab`}
                tabIndex="0"
              >
                {tabData[elem][3]}
              </div>
            );
          })}
        </div>
      </div>
      {/* product tab conten end */}

      {/* <h2>Yore Pay</h2> */}
      <Footer />
    </div>
  );
}

export default YoreFinances;
