import React, { useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
// import '../assets/css/patti.css';

// import ScrollToPlugin from "gsap/ScrollToPlugin";
// import ScrollSmoother from "gsap/ScrollSmoother";

import yoro from "../assets/images/yoro.png";

function YoreAi() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // let wc = gsap.timeline({

    //     scrollTrigger: {
    //         trigger: ".why-pin",
    //         // pin: ".yore-ai-page .blue-curv-section",
    //         pin: true,
    //         // pinSpacing: true,
    //         markers: true,
    //         start:"38% 50%",
    //         end:"100% 50%",
    //         scrub:2,
    //         pin:true
    //     }

    // });

    // wc
    // .to(".why-card1",{
    //     top: "35%",
    // },'a')
    // .to(".why-card2",{
    //     top: "130%"
    // },'a')
    // .to(".why-card2",{
    //     top: "42%"
    // },'b')
    // .to(".why-card1",{
    //     width: "65%",
    //     height: "65vh"
    // },'b')
    // .to(".why-card3",{
    //     top: "130%"
    // }, 'b')
    // .to(".why-card3",{
    //     top: "50%"
    // }, 'c')
    // .to(".why-card2",{
    //     width: "70%",
    //     height: "70vh"
    // },'c')

    // const cardds = gsap.utils.toArray(".cardd");
    // const spacer = 20;
    // const minScale = 0.8;

    // const distributor = gsap.utils.distribute({ base: minScale, amount: 0.2 });

    // cardds.forEach((cardd, index) => {

    //   const scaleVal = distributor(index, cardds[index], cardds);

    //   const tween = gsap.to(cardd, {
    //     scrollTrigger: {
    //       trigger: cardd,
    //       start: `top top`,
    //       scrub: true,
    //       markers: true,
    //       invalidateOnRefresh: true
    //     },
    //     ease: "none",
    //     scale: scaleVal
    //   });

    //   ScrollTrigger.create({
    //     trigger: cardd,
    //     start: `top-=${index * spacer} top`,
    //     endTrigger: '.cardds',
    //     end: `bottom top+=${200 + (cardds.length * spacer)}`,
    //     pin: true,
    //     pinSpacing: false,
    //     markers: true,
    //     id: 'pin',
    //     invalidateOnRefresh: true,
    //   });
    // });

    //let links = gsap.utils.toArray("nav a");

    let why = gsap.timeline({
      scrollTrigger: {
        trigger: ".yore-ai-page .blue-curv-section .curv-cont",
        pin: ".yore-ai-page .blue-curv-section .curv-cont",
        // pin: ".why-pin",
        // pinSpacing: true,
        // markers: true,
        start: "top-=80 top", // when the top of the trigger hits the top of the viewport
        // start: "30% 160px", // when the top of the trigger hits the top of the viewport
        end: "bottom+=2000 top", // end after scrolling 1000px beyond the start
        // end: "bottom+=2000", // end after scrolling 1000px beyond the start
        scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
    });
    // tl.addLabel("why-card1");
    why.to(".why-card1", {
      yPercent: 0,
      opacity: 1,
    });

    why.from(".why-card2", {
      yPercent: 160,
      opacity: 1,
    });

    why.to(
      ".why-card1",
      {
        scale: 0.9,
        //   scale:0.95,
        yPercent: -0.5,
        opacity: 1,
      },
      "-=0.5"
    );

    why.to(".why-card2", {
      yPercent: 0,
      opacity: 1,
    });

    why.from(".why-card3", {
      yPercent: 160,
      opacity: 1,
    });

    why.to(
      ".why-card2",
      {
        scale: 0.95,
        yPercent: -0.4,
        opacity: 1,
      },
      "-=0.5"
    );

    why.to(".why-card3", {
      yPercent: 0,
      opacity: 1,
    });

    // tl.addLabel("why-card2");
    // set the active section based on the direction, and position it part-way through the transition because that's more intuitive
    // tl.add(() => setActiveNav(tl.scrollTrigger.direction > 0 ? 1 : 0), "-=0.15");

    // tl.addLabel("why-card3");
    // tl.add(() => setActiveNav(tl.scrollTrigger.direction > 0 ? 2 : 1), "-=0.15");
  }, []);

  return (
    <div className="yore-ai-page">
      <Header />

      {/* Start Section*/}
      <div className="section-01">
        <div className="container">
          <div className="conten-box">
            <div className="row">
              <div className="col-md-6 my-auto">
                <h3
                  className="y-title mb-2 dark-blue-tx"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Your personal
                  <br />
                  financial genie
                </h3>
                <div className="d-md-none d-block">
                  <img src={yoro} className="img-fluid animated" alt="" />
                </div>
                <p className="txt-35 fw-500 yor-tx-grey">
                  Who grants <span className="blue-tx">yore</span> every wish
                </p>
              </div>
              <div className="col-md-6 d-md-block d-none">
                <div className="y-card text-center">
                  <img src={yoro} className="img-fluid animated" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Section */}
      {/* Section Start */}
      <div className="section-02">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="y-card">
                <div className="row align-items-center">
                  <div className="col-md-8 mx-auto text-center my-md-5 my-4">
                    <h2 className="y-title yor-tx-blue mb-2">
                      You work hard for <span className="blue-tx">yore</span>{" "}
                      money
                    </h2>
                    <p className="txt-35 fw-500 yor-tx-grey">
                      Let YORO work smart for it. Because who doesn't love a
                      smarter ally?
                    </p>
                    {/* <h5 className="y-title">Get banking at lightning speed</h5> */}

                    {/* <button className="yor-btn yor-bg-blue yor-tx-white mt-4 px-5 py-3">Let's play instead</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End */}
      {/* Section Start */}
      <div className="section-03">
        <div className="blue-curv-section">
          <div className="top-curv">
            <span></span>
          </div>
          <div className="curv-cont">
            <div className="container">
              <div className="why-pin">
                <div className="row ">
                  <div className="col-lg-7 m-auto">
                    <h3 className="titl-h3 mb-md-5 mb-4">Why Yore?</h3>
                  </div>
                  <div className="col-lg-12">
                    <div className="why-cardsss">
                      {/* <h3 className="titl-h3 mb-md-5 mb-4">Why Yore?</h3> */}
                      {/* list area start */}
                      <div className="why-card custom-card why-card1">
                        <div className="row">
                          <div className="col-md-5 d-md-block d-none">
                            <div className="animi-expand-box"></div>
                          </div>
                          <div className="col-md-7 my-auto">
                            <div className="box-cont">
                              <h3 className="y-title text-white mb-3 mob-w-75 mob-mx-auto">
                                Get the VIP treatment for your finances
                              </h3>
                              <div className="d-md-none d-block">
                                <div className="mob-animi-box"></div>
                              </div>
                              <p className="txt-35 fw-500 lh-sm text-white my-2">
                                YORO offers personalized guidance and expert
                                advice
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* list area end */}
                      {/* list area start */}
                      <div className="why-card custom-card why-card2">
                        <div className="row">
                          <div className="col-md-5 d-md-block d-none">
                            <div className="animi-expand-box"></div>
                          </div>
                          <div className="col-md-7 my-auto">
                            <div className="box-cont">
                              <h3 className="y-title text-white mb-3 mob-w-75 mob-mx-auto">
                                Get the VIP treatment for your finances
                              </h3>
                              <div className="d-md-none d-block">
                                <div className="mob-animi-box"></div>
                              </div>
                              <p className="txt-35 fw-500 lh-sm text-white my-2">
                                YORO offers personalized guidance and expert
                                advice
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* list area end */}
                      {/* list area start */}
                      <div className="why-card custom-card why-card3">
                        <div className="row">
                          <div className="col-md-5 d-md-block d-none">
                            <div className="animi-expand-box"></div>
                          </div>
                          <div className="col-md-7 my-auto">
                            <div className="box-cont">
                              <h3 className="y-title text-white mb-3 mob-w-75 mob-mx-auto">
                                Get the VIP treatment for your finances
                              </h3>
                              <div className="d-md-none d-block">
                                <div className="mob-animi-box"></div>
                              </div>
                              <p className="txt-35 fw-500 lh-sm text-white my-2">
                                YORO offers personalized guidance and expert
                                advice
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* list area end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-curv">
            <span></span>
          </div>
        </div>
      </div>
      {/* Section End */}

      {/* Section Start */}
      <div className="section-04">
        <div className="container">
          {/* <div className="row">
                        <div className="col-md-10 mx-auto text-center mb-5">
                            <h3 className="y-title yor-tx-blue mb-2">Experience the thrill of knowledge about money management</h3>
                            <h4 className="y-title yor-tx-grey">While earning real rewards along the way</h4>
                        </div>
                    </div> */}
          <div className="row">
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-md-3">
                      Intelligent <br />
                      insights
                    </h3>
                    <p className="y-para">
                      Yoro! Our A.I.-powered finance manager provides
                      personalized insights based on your data to help improve
                      your financial health
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-md-3">
                      Multilingual <br />
                      Support
                    </h3>
                    <p className="y-para">
                      They say Money talks, but Yoro speaks yore language - the
                      perfect ally you've been waiting for
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-3">
                      Debt
                      <br />
                      management
                    </h3>
                    <p className="y-para">
                      Say goodbye to debt stress and Let Yoro help you create a
                      plan to pay it off faster{" "}
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-3">
                      Predictive
                      <br />
                      analysis
                    </h3>
                    <p className="y-para">
                      Let Yoro be your financial fortune-teller - predict your
                      future financial status and achieve your goals with ease
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-3">
                      Financial literacy
                      <br />
                      education
                    </h3>
                    <p className="y-para">
                      Learn the language of finance! Yoro offers expert
                      resources to boost your financial knowledge
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-3">
                      24/7
                      <br />
                      availability
                    </h3>
                    <p className="y-para">
                      No more waiting - get financial guidance and advice
                      anytime, anywhere Yoro's 24/7 availability
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-3">
                      Budgeting &<br />
                      spending tracking
                    </h3>
                    <p className="y-para">
                      Budgeting made easy - Yoro helps you stick to your budget
                      and save money effortlessly
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="y-card">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="y-title yor-tx-blue mb-3">
                      Investment
                      <br />
                      advice
                    </h3>
                    <p className="y-para">
                      Invest like a pro! Yoro will recommend personalized
                      investment opportunities that fit your
                      goals and preferences
                    </p>
                    <div
                      className="yi-animi-box"
                      style={{ minHeight: 300 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End */}

      <Footer />
    </div>
  );
}

export default YoreAi;
