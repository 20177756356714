import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";
// import { BrowserRouter } from 'react-router-dom';
import "./assets/fonts/Gilroy.css";
// import './assets/css/Responsiv.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.querySelector('#root'));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>

  // <BrowserRouter basename="yoreweb">
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
