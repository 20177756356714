import React, { useEffect } from "react";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// import $ from "jquery";

import "./slideCardsStyle.css";

function SlideCards({ data }) {
  useEffect(() => {
    //GSAP Code start
    gsap.registerPlugin(ScrollTrigger);

    let rwd = gsap.timeline({
      scrollTrigger: {
        trigger: ".card-section .section-05 ",
        pin: true,
        // markers: true,
        start: "top 18%",
        end: "bottom+=2000 18%",
        scrub: 2,
      },
    });

    // rwd.to(".rwd-box:first-child", { yPercent: 0 });
    rwd.fromTo(".rwd-box:nth-child(2)", { xPercent: 93 }, { xPercent: 7 });
    rwd.fromTo(".rwd-box:nth-child(3)", { xPercent: 100 }, { xPercent: 14 });
  }, []);

  return (
    <>
      <div className="card-section">
        <div className="container">
          <div className="section-05">
            <div className="rwd-area">
              {data.map((card) => {
                return (
                  <div className="rwd-box">
                    <div className="rd-titl">
                      <h6>{card[0]}</h6>
                    </div>
                    <div className="d-lg-flex align-items-center">
                      <div className="rd-img">
                        <img
                          src={card[3]}
                          className="img-fluid animated bobo"
                          alt=""
                        />
                      </div>
                      <div className="rd-cont">
                        <h4>{card[1]}</h4>
                        {card[2]}
                        <a class="btn-default" href={card[4]}>
                          Learn more
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SlideCards;
