import { React, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import $ from "jquery";

// import { gsap } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

import BankingPay from "../components/yore-pay/BankingPay";
import SplitPay from "../components/yore-pay/SplitPay";
import PostpaidAccountPay from "../components/yore-pay/PostpaidAccountPay";
import CardsPay from "../components/yore-pay/CardsPay";
import CryptoPay from "../components/yore-pay/CryptoPay";

// import ResultTable from "../components/result-table/ResultTable";
// import DownloadApp from "../components/download/DownloadApp";
// import Graph from '../../src/assets/images/Untitled design (4).png';
import crvLeft from "../../src/assets/images/crv-left.png";
import crvRight from "../../src/assets/images/crv-right.png";

import pay1 from "../../src/assets/images/pay1.png";
import pay2 from "../../src/assets/images/pay2.png";
import pay3 from "../../src/assets/images/pay3.png";
import pay4 from "../../src/assets/images/pay4.png";
import pay5 from "../../src/assets/images/pay5.png";

// import Marquee from "react-fast-marquee";

import "../../src/components/footer/Footer.css";
import "../../src/assets/css/Style.css";

let tabData = {
  banking: [
    "Banking",
    "Managing yore money has never been easier! ",
    "Make it simple as child's play",
    <BankingPay />,
  ],
  split_pay: [
    "Split Pay",
    "Say goodbye to awkward money conversations",
    ["With your Siblings.", "With your Friends."],
    <SplitPay />,
  ],
  postpaid_account: [
    "Postpaid Account",
    "Money worries be gone,When yore Postpaid is your Companion",
    "Always there when you need it most!",
    <PostpaidAccountPay />,
  ],
  cards: [
    "Cards",
    "Your cards, yore way all in one place",
    "Win rewards & cashback everyday",
    <CardsPay />,
  ],
  crypto: [
    "Crypto",
    "Money has evolved, shouldn't you?",
    "Embrace the future with Crypto currencies",
    <CryptoPay />,
  ],
};

function YourPay({ dValue = "banking" }) {
  const [activeTab, setActiveTab] = useState(dValue);

  // gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    var actWidth = $(".prod-bar .nav-tabs")
      .find(".active")
      .parent("li")
      .width();
    var actPosition = $(".prod-bar .nav-tabs .active").position();
    $(".prod-bar .slider").css({ left: +actPosition.left, width: actWidth });
  });

  return (
    <div className="yore-pay-page">
      <Header />

      <div className="titl-section">
        <div className="container">
          <div className="prod-titl text-center">
            <h2>
              Power up with <span className="blue-tx">yore</span> payments
            </h2>
            <h4 className="yor-tx-blue txt-35 py-2">
              Ignite your financial wellness
            </h4>
            <button className="yor-btn yor-bg-blue yor-tx-white mt-3 px-5 py-3">
              Get Exclusive Access{" "}
            </button>
          </div>
        </div>

        <div className="payBnrGrphis">
          <div className="BnrGrap grpOne">
            <img src={pay1} alt="Banner Graphics One" />
          </div>
          <div className="BnrGrap grpTwo">
            <img src={pay2} alt="Banner Graphics Two" />
          </div>
          <div className="BnrGrap grpThree">
            <img src={pay3} alt="Banner Graphics Three" />
          </div>
          <div className="BnrGrap grpFour">
            <img src={pay4} alt="Banner Graphics Four" />
          </div>
          <div className="BnrGrap grpFive">
            <img src={pay5} alt="Banner Graphics Five" />
          </div>
        </div>
      </div>

      <div className="yore-prod-tab-section">
        <div className="container">
          <div className="tile prod-bar">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {Object.keys(tabData).map((elem) => {
                return (
                  <li className="nav-item" role="presentation">
                    <a
                      href="/#"
                      className={`nav-link ${dValue === elem ? "active" : ""}`}
                      id={`${elem}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${elem}`}
                      type="button"
                      role="tab"
                      aria-controls={elem}
                      aria-selected="true"
                      onClick={() => setActiveTab(elem)}
                    >
                      <span></span>
                      <h6>{tabData[elem][0]}</h6>
                    </a>
                  </li>
                );
              })}

              <div className="slider">
                <div className="curv-left">
                  <img
                    src={crvLeft}
                    className="crv-left"
                    alt="left-curv-icon"
                  />
                </div>
                <div className="curv-right">
                  <img
                    src={crvRight}
                    className="crv-right"
                    alt="right-curv-icon"
                  />
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>

      {/* Section Start */}
      <div className="blue-curv-section">
        <div className="top-curv">
          <span></span>
        </div>
        <div className="curv-cont">
          <div className="container">
            <div className="row top-space">
              <div className="col-lg-10 m-auto text-center">
                <h3 className="titl-h3">{tabData[activeTab][1]}</h3>
                <h5 className="fs-3 pt-2 text-primary">
                  {tabData[activeTab][2]}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-curv">
          <span></span>
        </div>
      </div>
      {/* Section End */}

      {/* product tab conten start */}
      <div className="yore-product-section">
        <div className="tab-content" id="myTabContent">
          {Object.keys(tabData).map((elem) => {
            return (
              <div
                className={`tab-pane fade ${
                  dValue === elem ? "show active" : ""
                }`}
                id={elem}
                role="tabpanel"
                aria-labelledby={`${elem}-tab`}
                tabIndex="0"
              >
                {tabData[elem][3]}
              </div>
            );
          })}
        </div>
      </div>
      {/* product tab conten end */}

      {/* <h2>Yore Pay</h2> */}
      <Footer />
    </div>
  );
}

export default YourPay;
